.aboutImage {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.about-description {
  font-size: 0.9rem;
  color: #6f6f6f;
}
