/**
 * = Custom forms
 */

.custom-control-label {
    // Background-color and (when enabled) gradient
    &:before {
        border: $custom-checkbox-indicator-border-width solid $white;
        box-shadow: $custom-control-box-shadow;
        @if $enable-transitions {
            transition: $input-transition;
        } 
    }

    span {
        position: relative;
        top: 2px;
    }
} 

// Custom switch large

.custom-switch{
    &.switch-lg{
        .custom-control-label{
            &:before{
                height: $custom-switch-height;
                width: 4.6625rem;
                border-radius: $border-radius-pill;
            }
            &:after{
                top: calc(#{(($font-size-base * $line-height-base - $custom-control-indicator-size) / 2)} + #{$custom-control-indicator-border-width * 5});
                left: calc(#{-($custom-switch-width + $custom-control-gutter)} + #{$custom-control-indicator-border-width * 5});
                background-color: $primary;
            }
        }
    }
}

.custom-control-label {
    margin-bottom: 0;
}

.custom-control-input {
    &:active~.custom-control-label::before {
        border-color: $custom-control-indicator-active-border-color;
    }
}

// Custom File Input

.custom-file-label{
    border: $custom-control-border-width solid $custom-control-border-color;
    font-size: $input-font-size;
    color:$input-placeholder-color;
    text-align: left;
    &::after {
        height: 100%;
        background-color: $gray-300;
        font-weight:$font-weight-normal;
        border:0;
    }
}

.custom-file-input{
    &:not(:disabled):hover {
        cursor: pointer;
    
        & ~ .custom-file-label,
        & ~ .custom-file-label:before {
          border-color: $gray-300;
        }
    }
}


//Select
.custom-select{
    // font-size: $input-btn-font-size;
    box-shadow: none;
    border: $border-width solid $input-border-color;

    &.custom-select-shadow {
        box-shadow: $input-box-shadow;
        border: 0;
        transition: box-shadow .15s ease;
    
        &:focus {
            box-shadow: $shadow-input-focus;
        }
    }

    &:hover {
        cursor: pointer;
    }
}


